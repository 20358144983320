/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from "axios";
import router from "@/router/index";
import {Message} from 'element-ui';
import { createHash } from "crypto";

//默认超时时间
axios.defaults.timeout = 300000;
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

// 加密key
const key = '14e628db9c7b33d331cda8042b3ea40f67746a43ca8459c584e82e47acabef40'

//http request拦截
axios.interceptors.request.use(
  (config) => {
    //让每个请求携带token 2024年8月21日 放弃用户体系
    // const accessToken = localStorage.getItem("accessToken");
    // if (accessToken && accessToken != undefined && accessToken != null) {
    //   config.headers["token"] = accessToken;
    // }
    const timestamp = Math.round(new Date().getTime()/1000) //秒数
    const token= sha256("key="+key+"&timestamp="+timestamp)
    config.headers["timestamp"] = timestamp;
    config.headers["token"] = token;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function sha256(str){
  return createHash('sha256').update(str).digest("hex");
}


//http response 拦截
axios.interceptors.response.use(res => {

  //获取状态码
  const status = res.data.code || res.status;
  const message = res.data.message || res.data.error_description || '未知错误';

  //如果是401则跳转到登录页面
  if (status === 401)  router.push({path: '/login'})
  // 如果请求为非200否者默认统一处理
  if (status == "9999") {
    Message({
      message: message,
      type: 'error'
    });
    return Promise.reject(new Error(message))
  }
  return res;
}, error => {
  return Promise.reject(new Error(error));
});


export default axios;
