<template>
  <div class="home">
    <!-- section9 us-->
    <div class="home_section home_section_us bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text">
          <div class="row">
            <div class="col">
              <label class="title fc1">
                {{ this.$t("home.section9.prod.title") }}
              </label>
              <label class="sub fc2 pointer" @click="router('/product/zktrace')">
                {{ this.$t("home.section9.prod.sub1") }}
              </label>
              <label class="sub fc2 pointer" @click="router('/product/assetRecovery')">
                {{ this.$t("home.section9.prod.sub2") }}
              </label>
              <!-- <label class="sub fc2">
                {{ this.$t("home.section9.prod.sub3") }}
              </label> -->
            </div>

            <!-- <div class="col justify-content-right">
              <label class="title fc1">
                {{ this.$t("home.section9.case.title") }}
              </label>
              <label class="sub fc2">
                {{ this.$t("home.section9.case.sub1") }}
              </label>
            </div> -->

            <div class="col justify-content-right">
              <label class="title fc1">
                {{ this.$t("home.section9.us.title") }}
              </label>
              <label class="sub fc2 pointer" @click="router('/bitjungle')">
                {{ this.$t("home.section9.us.sub1") }}
              </label>
            </div>
          </div>
        </div>
        <div class="logo-intro col">
          <img :src="require(`@/assets/img/logo-light.png`)" width="160" height="46"/>
          <div class="fc2"> {{ this.$t("home.section9.logo") }} </div>
        </div>
      </div>
    </div>

    <!-- section10 us-->
    <div class="home_section home_section_version">
      <div class="home_section_container space-between-row fc2">
        <div>{{ this.$t("home.section10.version") }}</div>
        <div class="row">
          <img :src="require(`@/assets/img/icon-bottom-x.png`)" width="24" height="24"/>
          <img :src="require(`@/assets/img/icon-bottom-redbook.png`)" width="24" height="24"/>
          <img :src="require(`@/assets/img/icon-bottom-tiktok.png`)" width="24" height="24"/>
          <img :src="require(`@/assets/img/icon-bottom-wechat.png`)" width="24" height="24"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "bit-bottom",
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
      }
      if(t == null) t = 'dark'
      return t
    },
  },
  data() {
    return {
    };
  },
  methods: {
    router(path){
      this.$router.push({ path: path })
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  box-sizing: border-box;
  flex: 1;

  .home_section_us{
    height: 344px;
    .home_section_container{
      padding: 60px 0px;
      .home_section_text{
        width: auto;
        .row{
          gap: 80px;
          .col{
            width: 132px;
            height: 160px;
            gap: 16px;
            .title{
              font-size: 24px;
              font-weight: 400;
              line-height: 40px;
              height: 40px;
            }
            .sub{
              font-size: 16px;
              font-weight: normal;
              line-height: 24px;
              height: 24px;
            }
          }
          .col:nth-child(1){
            width: 253px;
          }
        }
      }
    }
    .logo-intro{
      gap: 16px;
      height: 160px;
      align-items: end;
      .fc2{
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        height: 24px;
      }
    }
  }

  .home_section_version{
    height: 64px;
    .home_section_container{
      height: 64px;
      padding: 20px 0px;
      div{
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        height: 24px;

        gap: 24px;
      }
    }
  }
}
</style>
